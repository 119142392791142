<template>
  <div id="emergencies" v-cloak>
    <div class="header">
      <van-row gutter="10">
        <van-col span="16" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />备案详情
        </van-col>
      </van-row>
    </div>
    <div class="emergencies-detail" v-if="archive_info.user">
      <div class="detail">
        <van-row>
          <van-col span="12">
            <div class="detail-title">用户：</div>
          </van-col>
          <van-col span="12">
            <div class="detail-title">{{ archive_info.user.username }}</div>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12">
            <div class="detail-title">游客电话：</div>
          </van-col>
          <van-col span="12">
            <div class="detail-title">{{ archive_info.user.mobile }}</div>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12">
            <div class="detail-title">备案电话：</div>
          </van-col>
          <van-col span="12">
            <div class="detail-title">{{ archive_info.mobile }}</div>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12">
            <div class="detail-title">目的地：</div>
          </van-col>
          <van-col span="12">
            <div class="detail-title">
              {{ archive_info.destination_address }}
            </div>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12">
            <div class="detail-title">入住地址：</div>
          </van-col>
          <van-col span="12">
            <div class="detail-title">{{ archive_info.live_address }}</div>
          </van-col>
        </van-row>

        <van-row>
          <van-col span="12"> </van-col>
          <van-col span="12">
            <div class="date" style="text-align: right">
              {{ archive_info.created_at }}
            </div>
          </van-col>
        </van-row>
      </div>
      <div class="message-tuan" v-if="archive_info.travel_list">
        <van-row class="tuan-info">
          <van-col span="10">
            <div class="tuan-start-time text-left">
              {{ archive_info.travel_list.start_date }}
            </div>
            <div class="tuan-start-address text-left">
              {{
                archive_info.travel_list.start_addr
                  ? archive_info.travel_list.start_addr
                  : "暂无"
              }}
            </div>
          </van-col>
          <van-col span="4">
            <div class="need-time">{{ archive_info.travel_list.days }}天</div>
            <!-- <div class="tuan-items" @click.stop="onShowClick(item)">
              行程景点
            </div> -->
          </van-col>
          <van-col span="10">
            <div class="tuan-end-time text-right">
              {{ archive_info.travel_list.end_date }}
            </div>
            <div class="tuan-end-address text-right">
              {{
                archive_info.travel_list.end_addr
                  ? archive_info.travel_list.end_addr
                  : "暂无"
              }}
            </div>
          </van-col>
        </van-row>

        <!-- <div class="start-date">出发日期：{{ archive_info.travel_list.start_date }}</div> -->
        <div class="start-date">
          团名：{{ archive_info.travel_list.list_name }}
        </div>
        <div class="start-date" style="margin-top: 0">
          出发日期：{{ archive_info.travel_list.start_date }}
        </div>
        <div class="tuan-ren-number">
          团队人数：{{
            archive_info.travel_list.tourists_count
              ? archive_info.travel_list.tourists_count
              : 0
          }}人
        </div>
        <div :class="[stateClass[archive_info.travel_list.status]]">
          {{ archive_info.travel_list.status_text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "archive-info-item",
  data() {
    return {
      refreshing: false,
      loading: true,
      archive_info: {},
      stateClass: [
        "status-btn-plan",
        "status-btn-ing",
        "status-btn-completed",
        "status-btn-cancel",
      ],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },

    onReplytClick(approve_status) {
      this.archive - info_reply(approve_status);
    },
    async get_archive_info(id) {
      const res = await this.$apis.get_archive_info(id);
      this.archive_info = res.data;
      this.loading = false;
      this.refreshing = false;
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      const id = this.$route.query.id;
      if (!id) {
        Toast("参数错误");
        this.$router.go(-1);

        return false;
      }
      this.get_archive_info(id);
    },
  },
  components: {},
  created() {
    this.onRefresh();
  },
};
</script>

<style scoped>
#emergencies {
  height: 100vh;
  /* background: #f3f3f3; */
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.emergencies-detail {
  padding: 0.4rem;
  height: 90vh;
  overflow-y: scroll;
}
.detail {
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  padding: 0.4rem;
  margin-bottom: 0.3rem;
  width: 100%;
  white-space: normal;
  word-break: normal;
}
.detail-title {
  text-align: left;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}
.content {
  font-size: 14px;
  color: #666666;
  width: 100%;
  text-align: left;
  margin-top: 0.5rem;
  word-wrap: break-all;
  min-height: 1rem;
  word-break: break-all;
}
.date {
  font-size: 14px;
  color: #666666;
  text-align: right;
  margin-top: 0.5rem;
}
.reply-title {
  color: #333;
  font-size: 14px;
  height: 1rem;
  line-height: 1rem;
}
.reply-text-area {
  height: 3rem;
}
.reply-text-area {
  border-radius: 0.2rem;
  background: #ffff;
  width: 100%;
  padding: 0.3rem;
  font-size: 0.3rem;
}
[v-cloak] {
  display: none;
}
.status-btn-cancel {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  background: #6f6f6f23;
  color: #606060;
  padding: 2px 0.2rem;
}
.status-btn-completed {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  background: #13C2C212;
  color: #13C2C2;
  padding: 2px 0.2rem;
}
.status-btn-plan {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  color: #4168EE;
  background: #4168EE12;
  padding: 2px 0.2rem;
}
.status-btn-ing {
  font-size: 0.25rem;
  border-radius: 12x;
  border-radius: 12px;
  position: absolute;
  bottom: 0.4rem;
  right: 0.3rem;
  color: #fff;
  background: #4168EE;
  padding: 2px 0.2rem;
}
.message-tuan {
  background: #fff;
  padding: 0.4rem;
  border-radius: 20px;
  position: relative;
  margin-bottom: 10px;
}
.tuan-info {
  /* margin-top: 1.6rem; */
}
.tuan-start-time,
.tuan-end-time {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.tuan-start-address,
.tuan-end-address {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.tuan-items {
  font-size: 12px;
  color: #436def;
  text-align: center;
}
.need-time {
  font-size: 12px;
  color: #666666;
  text-align: center;
  border-bottom: 1px solid #cccccc;
}
.start-date {
  font-size: 12px;
  color: #666666;
  margin-top: 20px;
}
.tuan-ren-number {
  font-size: 12px;
  color: #666666;
}
</style>
